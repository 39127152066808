import { SimpleLookupModel } from "@/core/models";

export class CertificateStatisticsCollectionFilter {
  strandId?: number;
  levelId?: number;
  deliveryTypeId?: number;
  unionIds?: number;
  regionIds?: number;
  genderIds?: number;
}

export class ActiveCertificateStatisticsCollectionFilter extends CertificateStatisticsCollectionFilter {
  date1: Date;
  date2: Date;
}

export class IssuedCertificateStatisticsCollectionFilter extends CertificateStatisticsCollectionFilter {
  dateRange1: Date[];
  dateRange2: Date[];
}

export class CertificateStatisticsCollectionModel {
  strandId: number;
  strandName: string;
  total1: number;
  total2: number;
  increase?: number;
  courses: CertificateStatisticsCourseModel[];
}

export class CertificateStatisticsCourseModel {
  courseCode: string;
  courseName: string;
  value1: number;
  value2: number;
  increase?: number;
}

export class NormalizedCertificateStatisticsModel {
  rowType: CertificateStatisticsRowType;
  col1?: any;
  col2?: any;
  col3?: any;
  col4?: any;
  col5?: any;
}

export enum CertificateStatisticsRowType {
  Heading = 1,
  Data = 2,
  Totals = 3,
}

export class CertificateStatisticsGridLookupModel {
  regions: SimpleLookupModel[];
  unions: SimpleLookupModel[];
  strands: SimpleLookupModel[];
  levels: SimpleLookupModel[];
  deliveryTypes: SimpleLookupModel[];
  genders: SimpleLookupModel[];
}