export default {
  "common": {
    "name": "Nom",
    "hello": "Bonjour",
    "details": "Détails",
    "created": "Créé",
    "ok": "OK",
    "cancel": "Annuler",
    "save": "Enregistrer",
    "confirm": "Confirmer",
    "saveAsDraft": "Sauvegarder comme brouillon",
    "unpublish": "Dépublier",
    "id": "Id",
    "search": "Recherche",
    "status": "Statut",
    "view": "Voir",
    "course": "Cours",
    "union": "Fédération",
    "noData": "Pas de données",
    "startDate": "Date de commencement",
    "startTime": "Heure de commncement",
    "endDate": "Date de fin",
    "notes": "Remarques",
    "educators": "Formateurs",
    "forUser": "Pour utilisateur",
    "regions": "Régions",
    "unions": "Fédérations",
    "existingRegions": "Régions existantes",
    "existingUnions": "Fédérations existantes",
    "homeUnion": "Fédération britannique/irlandaise",
    "expiresOn": "Date d'expiration",
    "selectUser": "Sélectionner l'utilisateur",
    "none": "Aucun",
    "level": "Niveau",
    "actionAddAll": "Tout ajouter",
    "reason": "Raison",
    "gettingData": "Obtenir les données",
    "success": "Succès",
    "error": "Erreur",
    "actionEdit": "Modifier",
    "actionSubmit": "Envoyer",
    "logo": "Logo",
    "actionDropFile": "Déposer le fichier ici ou ",
    "imageFormatsAllowed1": "Fichiers JPG/PNG/GIF permis",
    "forename": "Prénom",
    "lastname": "Nom de famille",
    "emailAddress": "Adresse électronique",
    "email": "Courriel",
    "validationIsRequired": "requis",
    "code": "Code",
    "certified": "Certifié",
    "certExpiry": "Expiration de la certification",
    "session": "Session",
    "yes": "Oui",
    "no": "Non",
    "actionDownload": "Télécharger",
    "close": "Fermer",
    "user": "Utilisateur",
    "location": "Localisation",
    "from": "De",
    "to": "À",
    "type": "Type ",
    "validationError": "Erreur de validation",
    "validationErrorDescription": "Une erreur de validation s'est produite. Veuillez la corriger",
    "errorSaving": "Une erreur s'est produite lors de l'enregistrement dans la base de données",
    "saving": "Sauvegarde...",
    "addedOn": "Ajouté le",
    "address": "Adresse",
    "dateOfBirth": "Date de naissance",
    "reject": "Rejeter",
    "saveAndProgressToNextStage": "Enregistrer et passer à l'étape suivante",
    "nextStageIs": "L'étape suivante est",
    "warning": "Avertissement",
    "auditors": "Auditeurs",
    "firstName": "Prénom",
    "surname": "Nom de famille"
  },
  "navigation": {
    "courses": "Cours",
    "myCourses": "Mes cours",
    "courseAdmin": "Administration du cours",
    "training": "Formation",
    "mySessions": "Mes sessions",
    "sessionAdmin": "Admin de la session",
    "dashboard": "Tableau de bord",
    "navAbout": "Au sujet de",
    "navAdministration": "Administration",
    "navUnions": "Fédérations",
    "logout": "Déconnexion",
    "myProfile": "Mon profil",
    "coursesAttended": "Cours suivis",
    "licensesAwarded": "Licences accordées",
    "certification": "Certification",
    "fileshare": "Fileshare (partage de fichiers)",
    "correspondence": "Correspondance"
  },
  "mySessions": {
    "viewMyTrainingSession": "Voir mes sessions de formation",
    "hostTrainingSession": "Organiser une session de formation",
    "selectCourse": "Veuillez choisir un cours",
    "selectUnion": "Veuillez choisir une fédération",
    "selectLicense": "Veuillez choisir une licence",
    "licenseInfo": "Information sur la licence",
    "validIn": "Valide en",
    "language": "Langue",
    "unionToAdmin": "Fédération chargée de l'administration"
  },
  "filters": {
    "filterByRegion": "Filtrer par région",
    "filterByUnion": "Filtrer par fédération",
    "filterByStatus": "Filtrer par statut",
    "filterByQualityStatus": "Filtrer par statut concernant la qualité",
    "filterByGender": "Filtrer par genre",
    "filterByLevel": "Filtrer par niveau",
    "filterByStrand": "Filtrer par domaine",
    "filterByExpiry": "Filtrer par expiration",
    "filterByDeliveryType": "Filtrer par type de prestation",
    "pleaseSelectLevel": "Veuillez sélectionner le niveau",
    "pleaseSelectStrand": "Veuillez sélectionner le volet",
    "pleaseSelectRegions": "Veuillez sélectionner les régions",
    "pleaseSelectUnions": "Veuillez sélectionner les fédérations",
    "pleaseSelectStatus": "Veuillez sélectionner le statut",
    "filterByLanguage": "Filtrer par langue",
    "filterByCourse": "Filtrer par cours",
    "filterByAttendeeRegion": "Filtrer par la région des participants",
    "filterByAttendeeUnion": "Filtrer par la fédération des participants",
    "filterByUserEmail": "Filtrer par email de l'utilisateur",
    "filterByAssessmentStatus": "Filtrer par le statut d'évaluation",
    "filterByQualityAuditStatus": "Filtrer par le statut d'audit"
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "Voir les sessions de formation programmées",
    "scheduleTrainingSession": "Programmer une session de formation",
    "addTrainingSession": "Ajouter une session de formation"
  },
  "editTrainingSession": {
    "editTrainingSession": "Modifier une session de formation",
    "scheduledTraining": "Formation programmée",
    "certificateLanguage": "Langue du certificat ",
    "venueName": "Nom du lieu",
    "venueAddress": "Adresse du lieu",
    "organiser": "Organisateur",
    "selectAnEducator": "Sélectionner un formateur",
    "addEducator": "Ajouter un formateur",
    "addNoteToHistory": "Ajouter un commentaire à l'historique",
    "approve": "Approuver",
    "saveTrainingSession": "Enregistrer la session de formation",
    "viewTrainingSessionHistory": "Voir l'historique de la session de formation",
    "noHistory": "Aucun historique disponible",
    "addEducatorWarning": "Ce formateur peut vous aider mais ne peut pas diriger cette session. Chaque session requiert au moins un formateur principal.",
    "assistingEducators": "Formateurs adjoints",
    "canLead": "Peut diriger ?",
    "selectLanguage": "Veuillez sélectionner la langue",
    "scheduleCourse": "Programmer un cours",
    "saveCourse": "Sauvegarder un cours",
    "addCourse": "Ajouter un cours",
    "emailLanguageTooltip": "Les courriels aux participants sont rédigés dans cette langue",
    "startTimeLocalTime": "L'heure de commencement est spécifiée en heure locale"
  },
  "addNote": {
    "addNote": "Ajouter un commentaire",
    "noteDetails": "Détails du commentaire"
  },
  "courseSessionStatus": {
    "awaitingApproval": "En attente d'approbation",
    "rejected": "Rejeté",
    "approved": "Approuvé"
  },
  "tabs": {
    "tabattendees": "Participants",
    "tabhistory": "Historique",
    "tabCourseMaterials": "Matériels pour le cours"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "Voir les participants à la session de formation",
    "editAttendeeList": "Modifier la liste de participants",
    "attendeeName": "Nom",
    "assessmentStatus": "Statut d'évaluation",
    "actions": "Actions",
    "editAttendees": "Modifier les participants",
    "actionConfirm": "Confirmer",
    "noUsersFound": "Aucun utilisateur trouvé",
    "attendeeUpdateError": "Erreur d'actualisation du participant",
    "attendeeUpdateErrorDescription": "Une erreur s'est produite lors de l'actualisation de la liste de participants. Un modèle de courriel a-t-il été configuré pour la localisation de la session ?",
    "optionSelectStatus": "Sélectionner le statut",
    "actionUpdateStatus": "Actualiser le statut",
    "actionAdd": "Ajouter",
    "titleAddAssessment": "Ajouter une évaluation",
    "selectLanguage": "sélectionner la langue",
    "actionLicense": "Licence",
    "actionLicenseDescription": "Accorder une licence à ce participant",
    "actionEdit": "Modifier",
    "unmetPrerequisites": "Conditions préalables non satisfaites",
    "approveAreYouSure": "Êtes-vous sûr de vouloir approuver la certification des participants sélectionnés ?",
    "approveCertification": "Approuver la certification",
    "errorUpdatingStatusTitle": "Erreur d'actualisation du statut",
    "errorUpdatingStatusMessage": "Une erreur s'est produite lors de la vérification du statut des participants",
    "sendAttendeeInvites": "Envoyer un courriel d'invitation aux nouveaux participants :",
    "sendAttendeeInvitesToolTip": "Les courriels d'invitation des participants sont désactivés par défaut par la fédération hôte"
  },
  "editAssessment": {
    "editAssessment": "Modifier l'évaluation",
    "competency": "Compétence",
    "notYetCompetent": "Pas encore compétent",
    "notCompetent": "Pas compétent",
    "competent": "Compétent",
    "excellent": "Excellent",
    "criteria": "Critères",
    "commentHelpText": "Commentaire - Obligatoire en cas de « Pas encore compétent »",
    "actionSaveAndPublish": "Enregistrer et publier",
    "assessorComment": "Commentaires de l'évaluateur",
    "publishedBy": "Publié par",
    "lastUpdatedBy": "Dernière mise à jour par",
    "addedBy": "Ajouté par",
    "errorSavingAssessment": "Erreur lors de la sauvegarde de l'évaluation",
    "errorSavingAssessmentMessage": "Vérifier si toutes les entrées sont valides",
    "confirmCancelAssessment": "Êtes-vous sûr de vouloir annuler ?",
    "leaveAssessment": "Quitter l'évaluation?",
    "confirmPublishAssessment": "Êtes-vous sûr de vouloir publier cette évaluation ? Une fois publiée, l'évaluation ne peut plus être modifiée",
    "publishAssessment": "Publier l'évaluation ?",
    "viewAssessment": "Voir l'évaluation",
    "attendeeSelfReflection": "Autoréflexion du participant",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "Le participant sera-t-il un jour compétent ?",
    "attendeeMayBeCompetent": "Le participant peut encore être compétent (par défaut)",
    "attendeeNotCompetent": "Le participant ne sera jamais compétent",
    "attendeeNotCompetentConfirmation": "Êtes-vous sûr de vouloir publier cette évaluation comme non compétente ?",
    "unpublishAssessmentConfirmation": "Êtes-vous sûr de vouloir dépublier cette évaluation ?",
    "infoPublishAssessment": "Lorsque le participant a terminé l'évaluation, celle-ci peut être publiée en cochant le bouton ci-dessous",
    "publishAssessmentSwitchLabel": "Souhaitez-vous publier l'évaluation ?",
    "publishAssessmentActiveLabel": "Publier l'évaluation",
    "publishAssessmentInactiveLabel": "Sauvegarder et valider l'évaluation uniquement (par défaut)"
  },
  "feedback": {
    "viewFeedback": "Voir le retour d'information"
  },
  "activeCerts": {
    "activeCertificateStatistics": "Activer les statistiques du certificat",
    "date1": "Date 1",
    "date2": "Date 2",
    "activeCerts": "Certificats actifs",
    "pickDate": "Choisir une date",
    "total": "Total"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "Statistiques de certificat émis",
    "filterByDeliveryType": "Filtrer par type de prestation",
    "range1": "Éventail 1",
    "range2": "Éventail 2",
    "dateTo": "A",
    "regionUserFilters": "Filtres région / utilisateur",
    "regionUserFiltersDescription1": "Les filtres région et fédération sont appliqués différemment selon le type de prestation :",
    "regionUserFiltersDescription2": "En ligne & Scorm : les statistiques sont regroupées par fédération associée au participant",
    "regionUserFiltersDescription3": "Présentiel : les statistiques sont regroupées par fédération où se déroulait la formation",
    "courseCode": "Code cours",
    "courseName": "Nom du cours",
    "issuedCerts1": "Certificats 1 émis",
    "issuedCerts2": "Certificats 2 émis",
    "percentageIncrease": "% d'augmentation",
    "range1Start": "Début de l'intervalle 1",
    "range1End": "Fin de l'intervalle 1",
    "range2Start": "Début de l'intervalle 2",
    "range2End": "Fin de l'intervalle 2"
  },
  "strands": {
    "strandRugbyReady": "Rugby Ready",
    "strandLaws": "Règles du Jeu",
    "strandStrengthAndConditioning": "Préparation physique",
    "strandPlayerWelfare": "Santé du joueur",
    "strandKeepRugbyClean": "Keep Rugby Clean ",
    "strandCoaching": "Entraînement",
    "strandOfficiating": "Arbitrage",
    "strandIntegrity": "Intégrité",
    "strandMatchDayStaff": "Personnel du Jour de Match",
    "strand": "Volet"
  },
  "licenseStatus": {
    "licenseActive": "Actif",
    "licenseRevoked": "Révoqué",
    "licenseExpired": "Expiré",
    "licenseProvisional": "Temporaire"
  },
  "licenses": {
    "licenses": "Licences",
    "grantEducatorLicense": "Accorder une licence de formateur",
    "actionRevoke": "Révoquer",
    "grantALicense": "Accorder une licence",
    "grantLicense": "Accorder Licence",
    "certificatesAwarded": "Certificats délivrés",
    "actionGrantALicense": "Accorder une licence",
    "licenseRevoke": "Licences révoquées",
    "revokeLicense": "Licence révoquée",
    "noRevokeEmailHeader": "Pas de courriel de révocation automatique",
    "noRevokeEmailBody": "Un courriel automatique n'est pas envoyé quand une licence est révoquée. Les administrateurs souhaiteront peut-être communiquer cette action personnellement",
    "viewEligibleCourses": "Voir les cours éligibles",
    "eligibleCourses": "Cours éligibles",
    "messageLicenceAwardDelay": "Il y a un délai entre le moment où un manager attribue un certificat à un participant et celui où le certificat est délivré au participant. Le tableau ci-dessous ne montre que les certificats attribués, il est donc possible qu'un certificat récemment attribué à un participant n'apparaisse pas dans le tableau ci-dessous.",
    "messageEducatorCanTeach": "Un formateur ayant la licence proposée peut enseigner les cours suivants",
    "licenseAwardedTo": "Licence attribuée à",
    "errorAddingLicense": "Une erreur s'est produite lors de l'ajout de cette licence",
    "gettingLicenseInformation": "Obtenir les information sur la licence",
    "noLicenses": "Vous n'avez pas de licences actives pour le moment",
    "licenseHistory": "Historique des licences",
    "qualityStatus": "Statut concernant la qualité",
    "delayIssuingCertificate": "Note : Retard dans la délivrance des certificats",
    "delayIssuingCertificateInfo": "Il y a un décalage entre l'attribution d'un certificat par un manager à un participant et la délivrance du certificat au participant. Le tableau ci-dessous ne montre que les certificats attribués, il est donc possible qu'un certificat récemment attribué à un participant n'apparaisse pas dans le tableau ci-dessous.",
    "noActiveLicenses": "Aucune licence active",
    "addLicenseError": "Une erreur s'est produite lors de l'ajout de cette licence",
    "myLicenses": "Mes licences",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "La licence a été modifiée avec succès",
    "licenseAmendedConfirmationHeading": "Modifier la licence ?",
    "licenseAmendConfirmation": "Êtes-vous sûr de vouloir modifier cette licence ? Notez que cette mise à jour ne peut pas être annulée.",
    "expireLicense": "Expirer la licence",
    "amendLicense": "Modifier la licence"
  },
  "regionAdmin": {
    "rtmUser": "Utilisateur RTM",
    "saveRegion": "Enregistrer la région"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "Administrateur F&E de la Fédération",
    "canDeliverCoursesForWr": "Peut dispenser des cours au nom de World Rugby",
    "saveUnion": "Enregistrer la Fédération",
    "gettingUnion": "Obtenir la fédération",
    "unionSaved": "Fédération enregistrée"
  },
  "certStatus": {
    "certStatusInProgress": "En cours",
    "certStatusValid": "valide",
    "certStatusInExpired": "Expiré",
    "certStatusAll": "Tout"
  },
  "userAdmin": {
    "userList": "Liste d'utilisateurs",
    "inviteUser": "Inviter un utilisateur",
    "infoNoEmailInviteHeader": "Un courriel d'invitation ne sera pas envoyé",
    "infoNoEmailInviteBody1": "Cette fonction ajoute un utilisateur à la base de données avec le rôle backend Iris",
    "infoNoEmailInviteBody2": "Aucun courriel d'invitation n'est envoyé à l'utilisateur dans le cadre de cette fonctionnalité. Il incombe donc aux administrateurs d'iris de les informer qu'ils ont été ajoutés.",
    "infoNoEmailInviteBody3": "Si l'utilisateur ne l'a pas encore fait, il doit s'enregistrer auprès de WR SSO en utilisant l'adresse électronique fournie ici ",
    "actionAddUserAndAssignBackendRole": " Ajouter un utilisateur et assigner un rôle de backend",
    "irisUserAddedHeader": "Utilisateur backend Iris ajouté",
    "irisUserAddedBody": "a été ajouté comme utilisateur backend Iris",
    "errorAddingUserHeader": "Erreur lors de l'ajout de l'utilisateur backend",
    "errorAddingUserBody": "Une erreur s'est produite lors de l'ajout de l'utilisateur backend",
    "isExactEmail": "Est le courriel exact",
    "userBaseId": "Identifiant de base de l'utilisateur",
    "lastLoginDate": "Date de la dernière connexion",
    "coursesAndCertificates": "Cours et certificats",
    "roles": "Fonctions",
    "currentValidCertificates": "Certificats valides actuels",
    "userLicenses": "Licences d'utilisateur",
    "userNeverGrantedLicense": "Cet utilisateur n'a jamais reçu de licence",
    "infoRegionManagerheader": "Fonction de Responsable régional de la formation",
    "infoRegionManagerDetail1": "Le rôle de Responsable régional de la formation peut être attribué dans ",
    "infoRegionManagerDetail2": "le formulaire d'administration de la région",
    "infoUnionManagerheader": "Fonction de Responsable de la formation de la Fédération",
    "infoUnionManagerDetail1": "Le rôle de Responsable de la formation de la Fédération peut être attribué dans ",
    "infoUnionManagerDetail2": "le formulaire d'administration de la fédération",
    "userRoles": "fonctions d'utilisateur",
    "infoRolesUpdatedHeader": "Fonctions actualisées",
    "infoRolesUpdatedBody": "Les fonctions d'utilisateur ont bien été actualisées",
    "userExactEmail": "Utiliser l'adresse électronique exacte",
    "select": "Sélectionner",
    "noUsers": "Aucun utilisateur  ",
    "searchAttendee": "Chercher un participant pour l'ajouter"
  },
  "reports": {
    "reports": "Rapports",
    "report": "Rapport",
    "deliveryType": "Type de prestation",
    "actionGetReport": "Obtenir un rapport",
    "regions": "Région(s)",
    "unions": "Fédération(s)"
  },
  "qualityAudit": {
    "manage": "Gérer",
    "qualityAssurance": "Assurance qualité",
    "qualityAudits": "Audits qualité",
    "date": "Date",
    "addQualityAudit": "Ajouter un audit qualité",
    "noAuditsAdded": "Aucun audit n'a été ajouté à cette licence",
    "educatorCanTeach": "Un formateur avec la licence proposée peut enseigner les cours suivants :",
    "auditor": "Vérificateur",
    "published": "Publié",
    "actionPublish": "Publier  ",
    "audits": "Audits  ",
    "assignAuditor": "Affecter un vérificateur",
    "myAssignedQas": "Mes AQ affectées",
    "audit": "Audit",
    "auditBy": "Audit par",
    "publishedBy": "Cet audit a été publié par",
    "onDate": "le",
    "venue": "Lieu",
    "detailsPlaceholder": "Ajoutez des détails sur la manière dont l'audit a été réalisé. Inclure des liens en ligne si nécessaire",
    "areasOfStrength": "Points forts",
    "areasOfStrengthPlaceholder": "Ajouter les points forts ici",
    "areasForDevelopment": "Points à développer",
    "areasForDevelopmentPlaceholder": "Ajouter les points à développer ici",
    "agreedActionPlan": "Plan d'action convenu",
    "agreedActionPlanPlaceholder": "Ajouter le plan d'action convenu ici",
    "educatorSelfReflection": "Autoréflexion du formateur",
    "educatorSelfReflectionPlaceholder": "Ajouter l'autoréflexion du formateur ici",
    "grade": "Note  ",
    "gradePlaceholder": "Veuillez sélectionner la note",
    "behaviour": "Comportement",
    "behaviourPlaceholder": "Veuillez sélectionner le comportement",
    "saveAsDraft": "Sauvegarder comme brouillon",
    "auditsOnEducator": "Audits du formateur",
    "auditsByEducator": "Audits par le formateur",
    "educator": "Formateur",
    "addEducatorNote": "Ajouter note du formateur",
    "educatorNoteTooltip": "Les notes des formateurs seront visibles pour les formateurs et leurs responsables dans l'onglet \"Historique du cours\"",
    "qaSubmitSuccess": "Audit qualité soumis avec succès",
    "qaPublishedSuccess": "Audit qualité publié avec succès",
    "qaConfirmPublish": "Êtes-vous sûr de vouloir publier cet audit qualité ? L'audit ne sera pas modifiable une fois publié",
    "qaConfirmPublishTitle": "Publier l'audit ?",
    "qaLoadingText": "Acquisition des informations sur l'audit qualité...",
    "manageAudits": "Gérer les audits",
    "myActiveLicences": "Mes licences en vigueur",
    "editQualityAudit": "Modifier l'audit qualité",
    "submitForm": "Soumettre le formulaire",
    "auditPublished": "Audit publié",
    "user": "Utilisateur",
    "licenseHolder": "Détenteur de la licence",
    "licenseStatus": "Statut de la licence",
    "publishStatus": "Statut de publication",
    "actionSubmitEducatorFeedback": "Soumettre un retour d'information",
    "actionSubmitForEducatorFeedback": "Soumettre pour obtenir un retour d'information de l'audité",
    "filterByLicenseHolder": "Filtrer par détenteur de licence",
    "filterByAuditor": "Filtrer par auditeur",
    "filterByLicenseExpiry": "Filtrer par expiration de la licence",
    "filterByLicenseRegion": "Filtrer par la région de la licence",
    "filterByLicenseUnion": "Filtrer par la fédération (la licence s'applique)",
    "filterByAuditStatus": "Filtre par le statut d'audit",
    "auditStartDate": "Date de début de l'audit",
    "auditEndDate": "Date de fin de l'audit",
    "auditStatus": "Statut de l'audit",
    "auditDate": "Date de l'audit"
  },
  "qualityAuditStatus": {
    "Draft": "Projet",
    "AwaitingEducatorFeedback": "En attente des commentaires de l'audité",
    "AwaitingAuditeeFeedback": "En attente du retour d'information de l'audité",
    "AwaitingPublish": "En attente de publication",
    "Published": "Publié"
  },
  "termsAndConds": {
    "heading": "Accepter les conditions générales de World Rugby",
    "main": "Pour pouvoir utiliser Iris, vous devez accepter les conditions suivantes :",
    "educatorCodeOfConductHeading": "Code de conduite du formateur",
    "educatorLinkDescription": "Le code de conduite du formateur peut être consulté sur",
    "educatorAcceptCodeOfConduct": "J'accepte le code de conduite du formateur"
  },
  "courseSessionAttendees": {
    "removeAttendees": "Supprimer des participants",
    "addAttendees": "Ajouter des participants",
    "addAttendeesBy": "Ajouter des participants en",
    "draft": "Brouillon",
    "assessment": "Évaluation",
    "noAttendees": "Aucun participant",
    "noAttendeesSubTitle": "Aucun participant n'a été ajouté à ce cours. Cliquez sur le bouton ci-dessous pour ajouter des participants",
    "bulkAddAttendees": "Ajout en masse de participants par adresse électronique ",
    "findExisting": "Trouver un utilisateur existant",
    "registeringNew": "Enregistrer un nouvel utilisateur",
    "bulkAddByEmailA": "Ajoutez plusieurs participants à ce cours. Saisissez les adresses électroniques des participants dans le champ ci-dessous, une par ligne, puis cliquez sur le bouton \"Ajouter des participants\"",
    "bulkAddByEmailB": "Seuls les utilisateurs existants d'Iris seront ajoutés au cours. Une liste des adresses électroniques des participants qui n'ont pas pu être ajoutées vous sera fournie.",
    "addAttendeeLabel": "adresses électroniques des participants (une par ligne)",
    "addressesDoNotExist": "Les adresses électroniques des participants n'existent pas dans Iris",
    "emailsAreRegistered": "Les adresses électroniques suivantes ne sont pas enregistrées comme utilisateurs",
    "notPermittedToAccess": "Accès aux utilisateurs non autorisé",
    "emailsNotPermittedToAccess": "Vous n'êtes pas autorisé à accéder aux adresses électroniques suivantes",
    "registerNewUser": "Enregistrez un nouvel utilisateur et ajoutez-le en tant que participant à ce cours. Ne le faites que si l'utilisateur n'existe pas déjà dans Iris.",
    "firstName": "Prénom",
    "surname": "Nom de famille",
    "noEmailWarning": "Si le participant ne dispose pas d'une adresse électronique, des notifications par courriel seront envoyées au formateur organisateur",
    "registerNewUserAsAttendee": "Enregistrer un nouvel utilisateur comme participant",
    "draftAttendeeList": "Votre liste préliminaire de participants",
    "updateAttendeeList": "Êtes-vous sûr de vouloir mettre à jour la liste des participants ?",
    "confirmThisAction": "En confirmant cette action, tous les participants nouvellement ajoutés recevront un courriel d'introduction à ce cours.",
    "confirmTitle": "Mise à jour de la liste des participants ?",
    "errorAddingUserMessage": "Une erreur s'est produite lors de l'ajout d'un utilisateur",
    "errorAddingAttendees": "Une erreur s'est produite lors de l'ajout de participants"
  },
  "courseSessionHost": {
    "attendeeNotes": "Notes du participant",
    "attendeeNotesTooltip": "Ces notes apparaîtront dans les courriels d'invitation aux nouveaux participants (une fois configurés dans les modèles de courriel). Veuillez rédiger dans la langue sélectionnée",
    "addCourse": "Ajouter un cours",
    "noEducatorLicensesDescription": "Vous n'avez pas de licences actives. Vous avez besoin de licences de formateur actives pour dispenser des cours. Veuillez contacter votre responsable régional de la formation pour obtenir une licence de formateur",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "Voir mes cours",
    "viewScheduledCourses": "Voir les cours programmés",
    "hostCourse": "Organiser un cours",
    "scheduleCourse": "Programmer un cours",
    "editCourse": "Modifier un cours",
    "viewCourse": "Voir un cours",
    "viewAttendees": "Voir les participants",
    "viewCourseHistory": "Voir l'historique du cours"
  },
  "routeTags": {
    "acceptEducatorTerms": "Accepter les conditions générales du formateur"
  },
  "profileCourses": {
    "started": "Commencé",
    "noCoursesAttended": "Aucun cours suivi",
    "notAttendedAnyCourses": "Vous n'avez participé à aucun cours",
    "validCertLookupAll": "Tous",
    "validCertLookupCurrent": "Certificats valides actuels",
    "validCertLookupExpired": "Expirés",
    "validCertLookupInProgress": "En cours"
  },
  "profileLicenses": {
    "noLicenses": "Aucune licence",
    "noLicensesMatch": "Aucune licence ne correspond aux critères",
    "expiry": "Expiration   "
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "Afficher l'aperçu du courriel d'invitation",
    "attendeeEmailPreview": "Aperçu du courriel aux participants",
    "subject": "Objet",
    "htmlContent": "Contenu HTML",
    "textContent": "Contenu texte"
  },
  "myDashboard": {
    "myActions": "Mes actions",
    "recentCourseRequringApproval": "Cours récents nécessitant une approbation",
    "attendeesAwaitingCertApproval": "Participants en attente de l'approbation de la certification",
    "underMyAdmin": "Sous mon administration",
    "recentCourses": "Cours récents  ",
    "upcomingCourses": "Prochains cours",
    "auditsInProgress": "Audits en cours",
    "myRecentCourses": "Mes cours récents",
    "awaitingApproval": "En attente d'approbation",
    "myUpcomingCourses": "Mes prochains cours",
    "myQualityAudits": "Mes audits qualité",
    "myAuditsInProgress": "Mes audits en cours",
    "myUpcomingAudits": "Mes prochains audits",
    "recentUpcomingCourses": "Cours récents et à venir",
    "noCoursesSubtitle": "Vous n'avez pas de cours récent, et aucun cours à venir n'est prévu. Cliquez sur le bouton ci-dessous pour organiser un cours",
    "activeAudits": "Audits actifs",
    "noAuditsSubtitle": "Vous n'avez pas d'audit récent ni à venir.",
    "auditsRequiringMyFeedback": "J'ai besoin d'un retour d'information",
    "assessmentPeriodDueToExpire": "Évaluations proches de l'expiration",
    "assessmentsExpireInPastYear": "Les évaluations ont expiré au cours de l'année écoulée",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "Cours sur Fileshare (partage de fichiers)",
    "fileshareCourseMaterials": "Partage de fichiers de cours"
  },
  "correspondence": {
    "decline": "Refuser",
    "validateCorrespondence": "Valider la correspondance",
    "id": "ID",
    "emailScheduleType": "Type de correspondance",
    "status": "Statut",
    "targetedWorkforce": "Targeted workforce",
    "viewUsers": "Voir les utilisateurs",
    "hideUsers": "Cacher des utilisateurs",
    "attachments": "Pièces jointes",
    "approve": "Approuver",
    "validate": "Valider"
  },
  "educatorsEmail": {
    "numberOfUser": "Nombre d'utilisateurs",
    "noUsersFound": "Aucun utilisateur trouvé",
    "workforce": "Workforce",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "Conformité",
    "complianceCheckEdit": "Modifier un contrôle de conformité",
    "complianceCheckAdd": "Ajouter un contrôle de conformité",
    "compliantOnDate": "Conforme à la date",
    "complianceCheckSuccessfullySaved": "Votre contrôle de conformité a été enregistré avec succès",
    "requiredCertificates": "Certificats requis",
    "usersToCheck": "Utilisateurs à contrôler",
    "confirmRemoveRequiredCertificate": "Êtes-vous sûr de vouloir supprimer ce certificat obligatoire ?",
    "confirmRemoveUser": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
    "errorAddingUsers": "Une erreur s'est produite lors de l'ajout d'utilisateurs",
    "noCertificates": "Aucun certificat requis"
  },
  "educatorList": {
    "numberOfCoursesDelivered": "Nombre de cours dispensés",
    "numberOfUnionsDeliveredIn": "Nombre de fédérations concernées "
  },
  "agents": {
    "agents": "Agents",
    "applications": "Applications pour les agents",
    "updateAgentapplication": "Mise à jour de l'application agent",
    "knownAs": "Connu sous le nom de",
    "companyName": "Nom de l'entreprise",
    "companyAddress": "Adresse de l'entreprise",
    "numberOfClients": "Nombre de clients",
    "declarationOfGoodCharacter": "Déclaration de bonne moralité",
    "proofOfidentity": "Preuve d'identité",
    "supportingDocumentation": "Documents à l'appui",
    "confirmReject": "Confirmer le rejet de la demande",
    "confirmSaveAndProgress": "Confirmer l'enregistrement et passer à l'étape suivante",
    "applicationSuccessfullyUpdated": "La demande a été mise à jour avec succès",
    "errorUpdatingApplication": "Une erreur s'est produite lors de la mise à jour de l'application",
    "agentDoesNotExist": "Cette application d'agent n'existe pas",
    "hasCurrentAgentsCourseCertificate": "",
    "hasCurrentAgentsCoursePrerequisitesCertificate": ""
  },
  "auditorList": {
    "numberOfAuditsAssigned": "Nombre d'audits attribués",
    "numberOfStrandsAudited": "Nombre de domaines audités",
    "auditor": "Auditeur"
  }
};
